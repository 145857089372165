<template>
  <v-row justify="center" v-show="show">
    <v-dialog
      v-model="dialog"
      persistent
      max-width="500px"
      @click:outside="changeDialog(false)"
      transition="scale-transition"
      

    >
    
      <v-card id="dialog">
        <simplebar data-simplebar-auto-hide="false" id="simplebar-scroll">
 
        <div id="closeWrap">
          <CloseBtn @click="changeDialog(false)"/>
          </div>
        <div ref="scrollTo">
          
          <v-img
          v-if="getProductData(addCartId).image != ''"
          id="img"
          ref="img"
          :src="getProductData(addCartId).image"
          
        ></v-img>
        </div>
 
        <v-card-text>
          <br />
          <h2 id="title">{{ getProductData(addCartId).name }}</h2>
          <p id="price">{{ getProductData(addCartId).price.toFixed(2) }}€</p>
          <p id="description">{{ getProductData(addCartId).description }}</p>
          <div id="chips">
            <v-chip
              small
              color="var(--primary-color)"
              id="chip"
              v-for="diet in getProductData(addCartId).diets"
              :key="diet"
            >
              <v-icon left> mdi-egg-outline </v-icon>{{ diet }}
            </v-chip>
          </div>

          <div
            id="options"
            v-if="Object.keys(getProductData(addCartId).options).length"
          >

            <v-divider></v-divider>
            <h3 id="optionTitle">Suurus</h3>
            <v-radio-group
              id="radio"
              @change="changeOption"
              v-model="selectedOption"
            >
              <v-radio
                v-for="option in Object.keys(getProductData(addCartId).options)"
                :key="option"

                :value="option"
              >
                <template slot="label" id="optionLabel">
                  {{ option
                  }}<span
                    id="optionPrice"
                    v-if="getProductData(addCartId).options[option] != 0"
                    >{{ getProductData(addCartId).options[option] }}€</span
                  >
                               <span id="optionPrice" v-else>
                  {{ getProductData(addCartId).price }}€
                  </span>
                </template>
              </v-radio>
            </v-radio-group>
          </div>

          <div
            id="extras"
            v-if="Object.keys(getProductData(addCartId).extras).length"
          >


            <h3 id="optionTitle">Lisandid</h3>
            <div id="checks">
              <v-checkbox
                class="extraCheck ma-0 pa-0"
                v-for="extra in Object.keys(getProductData(addCartId).extras)"
                :key="extra"
                v-model="selectedExtras"
                :value="extra"
                @change="changeExtras"
              >
                <template slot="label">
                  {{ extra }}
                  <span
                    id="optionPrice"
                    v-if="getProductData(addCartId).extras[extra] != 0"
                    >{{ getProductData(addCartId).extras[extra] }}€</span
                  >
     
                </template>
              </v-checkbox>
            </div>
          </div>

          <div v-if="getProductData(addCartId).file" class="file">
            <p>Palun kirjutage, millist pilti soovite, või lisage pildi link märkmetesse. Kui teil on oma fail, siis tuleb peale tellimus sooritamist pilt saata rohuaiakohvik@gmail.com meilile.</p>
          </div>
          <div id="note">
            <br />

            <v-text-field
              label="Märkmed"
              hint="Nimi tordile, erisoovid jne"
              :value="note"
              @change="changeNote"
            ></v-text-field>
          </div>
        </v-card-text>
      </simplebar>
      <v-spacer class="space"></v-spacer>
        <v-card-actions id="actions">
          <v-spacer></v-spacer>

          <PlusMinusBtn
            :amount="amount"
            id="amountInput"
            @changeAmount="changeAmount"
          />
          <div class="add-wrapper">
            <v-btn color="var(--primary-color)" id="addCart" @click="addToCart()">
              Lisa ({{ getAddCardTotal() }}€)
            </v-btn>
          </div>
        </v-card-actions>

      
      </v-card>
    
    </v-dialog>
  </v-row>
</template>

<script>
import PlusMinusBtn from "./Extras/PlusMinusBtn.vue";
import CloseBtn from "./Extras/CloseBtn.vue";


import simplebar from 'simplebar-vue';
import 'simplebar-vue/dist/simplebar.min.css';





export default {
  name: "AddCart",
  components: {
    PlusMinusBtn,
    CloseBtn,
    simplebar,
  },
  props: {
    dialog: Boolean,
    addCartId: Number,
    cart: Object,
    unProducts: Array,
    amount: Number,
    selectedOption: String,
    selectedExtras: Array,
    note: String,
    rates: Object,
  },
  data() {
    return {
      show: false,

    }
  },
  methods: {
    top() {
      this.$refs.scrollTo.scrollIntoView({ behavior: "smooth" });
    },
    changeNote(e) {
      this.$emit("changeNote", e);
    },
    changeExtras(e) {
      this.$emit("changeExtras", e);
    },
    changeOption(e) {
      this.$emit("changeOption", e);
      console.log(e);
    },

    changeDialog(bool) {
      this.top();
      this.$emit("changeDialog", bool);
    },
    changeAmount(num) {
      this.$emit("changeAmount", num);
    },
    getProductData(id) {
      for (let i = 0; i < this.unProducts.length; i++) {
        if (this.unProducts[i].id == id) {
          return this.unProducts[i];
        }
      }
    },
    extraChange(e) {
      console.log(this.extraChange);
    },
    addToCart() {
      this.top();
      this.$emit("addToCart", true);
    },
    getAddCardTotal() {
      let totalPrice = 0;
      if (
        this.selectedOption == undefined
      ) {
        totalPrice += this.getProductData(this.addCartId).price;
      } else {
        totalPrice += this.getProductData(this.addCartId).options[
          this.selectedOption
        ];
      }
      for (let i = 0; i < this.selectedExtras.length; i++) {
        totalPrice += this.getProductData(this.addCartId).extras[
          this.selectedExtras[i]
        ];
      }

      totalPrice = totalPrice * this.amount;

      return (Math.round(totalPrice * 100) / 100).toFixed(2);
    },
  },
};
</script>

<style scoped>
#simplebar-scroll {
  max-height: 700px;
} 


@media (max-height: 800px) {
  #simplebar-scroll {
    max-height: 500px;
  }
}
#dialog {
  height: 100%;


}

.space {
  height: 66px;
}
#closeWrap {
  float: right;
  margin-right: 55px;
}
#close .v-icon {
  color: white;
}
.file {
  padding: 0 10px;
}
#actions {

  background: white;
  position: absolute;
  width: 100%;
  bottom: 0;
  padding: 0px 32px;
  padding-top: 0px;
  padding-bottom: 16px;
  border-bottom-left-radius: 25px;
  z-index: 999;
}
#actions > *{
  transform: translateZ(0)!important;
}
#radio .v-radio {
  margin-left: 10px;
}
.extraCheck {
  height: 30px;
}
#checks {
  margin-left: 10px;
}
#note {
  margin-left: 10px;
}
#amountInput {
  width: 50%;
  max-width: 150px;
  min-width: 150px;
  margin-right: 10px;
}

@media (max-width: 400px) {
  #amountInput {
  max-width: 120px;
  min-width: 120px;
}
}
.add-wrapper {
  width: 100%;
}
#addCart {
  width: 100%;
  color: white;
  font-weight: 700;
  height: 50px;
  font-size: 15px;
  margin: 0 10px 0 0px;
}

#img {
  width: 100%;
  margin: 0 auto;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

#title {
  color: rgb(0, 0, 0, 1);
  margin: 10px 5px 5px 10px;
  font-size: 28px;
  font-weight: 600;
  line-height: 28px;
}
#optionTitle {
  color: rgb(0, 0, 0, 0.7);
  margin: 10px 0px 5px 10px;
  padding-top: 16px;
  font-size: 21px;
  font-weight: 600;
}

#extras #optionTitle{
  padding-top: 0;
}
#checks  {
  padding-top: 16px;
}

#description {
  margin-left: 10px;
  font-size: 19px;
  font-weight: 500;
  margin-top: 10px;
  margin-bottom: 0;
}
#price {
  margin-left: 10px;
  margin-bottom: 0px;
  color: var(--primary-color);
  font-weight: 500;
  font-size: 17px;
  margin-top: 10px;
}
#chip {
  margin-right: 5px;
  margin-top: 5px;
  color: white;
  font-weight: bold;
}
#chips {
  margin-left: 10px;
  margin-top: 16px;
}

#optionPrice {
  color: var(--primary-color);
  margin-left: 5px;
}
@media (min-width: 640) {
  #dialog {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
  }
}


</style>

